<template>
    <nav class="sidebar__nav">
        <nav-list
            :data="navElements"
        />
    </nav>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "Navigation",
    components: {
        NavList : () => import('./NavList')
    },
    computed: {
        ...mapGetters('navigation', [
            'navElements'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.sidebar__nav {
    height: 100%;
}
</style>
